<template>
  <!-- 自发货 -->
  <div class="app-container">
    <el-row style="margin:20px 0;">
      <el-col v-if="terraceData.length>0" :span="24" style="display: flex;align-items: center;overflow-x: auto;"><span>{{ $t('title.Orderplatform') }}:</span>
        <div v-for="item in terraceData" :key="item.id">
          <span :class="{ 'active': form.sellPlatId === item.id }" style="text-align:center;display: block;width:80px;height:35px;line-height:35px" @click="selectTerrace('terrace',item)">{{ item.platformShortName }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin:20px 0;">
      <el-col v-if="siteData.length>0" :span="24" style="display: flex;align-items: center;overflow-x: auto;"><span>{{ $t('title.Platformsite') }}:</span>
        <span
          :class="{active: form.siteId === ''}"
          style="  text-align: center; display: block; width: 80px; height: 35px;  line-height: 35px;"
          @click="selectTerrace('site',{id:'',siteCode:''})"
        >
          {{ $t("title.OrderAll") }}</span>
        <div v-for="item in siteData" :key="item.id">
          <span :class="{ active: item.id === form.siteId }" style="text-align:center;display: block;width:80px;height:35px;line-height:35px" @click="selectTerrace('site',item)">{{ item.siteCode }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin:20px 0;">
      <template>
        <span>{{ $t('topic.RMAType') }}:
          <el-radio v-model="form.returnDetailType" label="" @change="changeType">{{ $t('title.OrderAll') }}</el-radio>
          <el-radio v-model="form.returnDetailType" label="0" @change="changeType">{{ $t('topic.Refunds') }}</el-radio>
          <el-radio v-model="form.returnDetailType" label="1" @change="changeType">{{ $t('topic.OnlyRefund') }}</el-radio>
          <el-radio v-model="form.returnDetailType" label="3" @change="changeType">{{ $t('title.Returnexchange') }}</el-radio>
        </span>
      </template>
    </el-row>
    <el-row style="margin:20px 0;">
      <template>
        <span>{{ $t('topic.RMAWay') }}:
          <el-radio v-model="form.returnType" label="-1">{{ $t('title.OrderAll') }}</el-radio>
          <el-radio v-model="form.returnType" label="1" @change="changeReturnType">{{ $t('topic.Manual') }}</el-radio>
          <el-radio v-model="form.returnType" label="0" @change="changeReturnType">{{ $t('topic.System') }}</el-radio>

        </span></template>
    </el-row>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :class="{ 'show-row': showRow }" class="row-input">
          <el-col :span="8">
            <el-form-item :label="$t('topic.ReturnOrderNumber')">
              <el-input v-model.trim="form.rmaId" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('topic.PlatformOrderNumber')">
              <el-input v-model.trim="form.orderCode" :placeholder="$t('tips.enterPurchaseOrder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SKU/FBM_SKU">
              <el-input v-model.trim="form.sku" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('topic.ReturnWarehouse')" prop="shipperAddress">
              <el-select
                v-model.trim="form.shipperAddress"
                :placeholder="$t('page.selectPlaceholder')"
                clearable
                filterable
              >
                <el-option
                  v-for="item in warehouseOptions"
                  :key="item.bizKey"
                  :label="item.bizValue"
                  :value="item.bizKey"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="display: flex;margin-left:10px">
            <el-form-item label-width="10px">
              <span style="display: inline-block;width: 90px;margin-right:10px"><el-select
                v-model="form.operatorName"
                clearable
                filterable
              >
                <el-option :label="$t('page.createByName')" value="0" />
                <el-option :label="$t('topic.Reviewer')" value="1" />
                <el-option :label="$t('topic.Moneymaker')" value="2" />
              </el-select></span>
              <span style="display: inline-block;width: 120px;"><el-input v-model="form.createByName" :placeholder="$t('page.inputPlaceholder')" />
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('title.Trackingforreturn')">
              <el-input v-model.trim="form.trackingId" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex;margin-left:20px">
            <el-form-item label-width="0px">
              <span style="display: inline-block;width: 110px;margin-right:45px"><el-select
                v-model="form.operatorType"
                clearable
                filterable
              >
                <el-option :label="$t('page.createTime')" value="0" />
                <el-option :label="$t('topic.Reviewtime')" value="1" />
                <el-option :label="$t('topic.Paymenttime')" value="2" />

              </el-select></span>
              <span style="display: inline-block;width: 100px;">
                <el-date-picker
                  v-model="form.date"
                  :end-placeholder="$t('title.endtime')"
                  :start-placeholder="$t('title.starttime')"
                  range-separator="~"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </span>
            </el-form-item>

          </el-col>

        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24" style="display:flex;height:36px;">
            <el-button @click="handlerefresh">{{ $t('title.Refresh') }}</el-button>
            <el-button :loading="exportLoading" @click="handleExport">{{ $t('page.export') }}</el-button>
            <el-button type="primary" @click="handleCancleRMA">{{ $t('topic.Batchcancel') }}</el-button>
            <router-link v-if="false" :to="{append:true, path: 'added', query: { ...userInfo}}"><el-button class="ml-2" type="primary" @click="changeCession">{{ $t('title.SetUpRMA') }}</el-button></router-link>
            <el-button v-if="isTimeout || isReceived" class="ml-2" type="primary" @click="handleBulkRefund">{{ $t('topic.BulkRefund') }}</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-row style="width:100%;margin:20px 0; background-color: #f9fafc;">
      <el-col v-if="statusData.length>0" :span="24" style="width:100%;overflow-x: scroll; padding: 15px 0px;-webkit-overflow-scrolling: touch;  white-space: nowrap; overflow-y: hidden;">
        <span :class="{ 'active': form.returnStatus === ''}" class="row-bg" style="display: inline-block;padding:2px 10px; margin-left:10px;cursor:pointer" @click="selectStatus('')"> {{ $t('title.All') }}  </span>
        <div v-for="(item,index) in statusData" :key="index" class="row-bg" style="cursor:pointer;" @click="selectStatus(item)">
          <span> <i style="text-align: center;width:20px;display: inline-block">|</i></span>
          <span :class="{ 'active': form.returnStatus === item.returnStatus }" style="padding:5px 0px 5px 10px;">{{ getReturnStatus(item.returnStatus) }}</span>
          <span :class="{ 'active': form.returnStatus === item.returnStatus }" style="padding:5px 10px 5px 0;color:red"> {{ '('+item.statusCount+')' }}</span>
        </div>
      </el-col>
    </el-row>
    <el-table
      ref="multipleTable"
      v-loading="tableDatasLoading"
      :data="tableData"
      :expand-row-keys="expands"
      :header-cell-style="{background:'#fafafa'}"
      :row-key="getRowKeys"
      align="left"
      class="mb-3"
      max-height="650px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-row :span="24" class="expand-row border" type="flex">
            <el-col :span="7">{{ $t('topic.productname') }}</el-col>
            <el-col :span="3">{{ $t('title.Quantity') }}</el-col>
            <el-col :span="3">{{ $t('topic.Numberofrefunds') }}</el-col>
            <el-col :span="5">{{ $t('topic.reasonforreturn') }}</el-col>
            <el-col :span="3">{{ $t('title.orderamount') }}</el-col>
            <el-col :span="2">{{ $t('topic.Refundamount') }}</el-col>
            <el-col :span="3">{{ $t('title.Buyermessage') }}</el-col>
            <el-col :span="4">{{ $t('title.Trackingforreturn') }}</el-col>
            <el-col :span="4">{{ $t('topic.Receiptstatus') }}</el-col>
            <el-col :span="4" />
          </el-row>
          <!-- 换成唯一key -->
          <el-row v-for="(item, index) in props.row.detailList" :key="index" :span="24" class="expand-row border" type="flex">
            <el-col :span="7" style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ item.sku }}</el-col>
            <el-col :span="3">{{ item.skuNumber }}</el-col>
            <el-col :span="3">{{ item.returnNumber }}</el-col>
            <el-col :span="5" style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ item.returnReasonCodeCN }}</el-col>
            <el-col :span="3">{{ item.itemPrice }}</el-col>
            <el-col :span="2">{{ item.refundAmount }}</el-col>
            <el-col :span="3" style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ item.sellComments }}</el-col>
            <el-col :span="4"><a :href="'https://t.17track.net/zh-cn#nums='+item.trackingId" style="color:#3296fa" target="_blank">{{ item.trackingId }}</a></el-col>
            <el-col :span="4">{{ item.returnStatus }}</el-col>
            <el-col :span="4">
              <span v-if="props.row.returnStatus == 2 && (item.trackingId ==='' || item.trackingId === undefined ) ">
                <el-button size="small" type="text" @click="handleMakeup(item,props.row)">补录物流单</el-button>
              </span>
              <span v-if="props.row.returnStatus == 2 && item.trackingId && props.row.returnType === 1">
                <el-button size="small" type="text" @click="handleUpdateTrackingId(item)">{{ $t('title.Update') + $t('order.Trackingnumber') }}</el-button>
              </span>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        type="selection"
      />
      <el-table-column
        :label="$t('page.No')"
        align="center"
        type="index"
        width="70"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('topic.ReturnOrderNumber')"
        prop="rmaId"
        width="125"
      >
        <template slot-scope="scope">
          <p>
            <router-link :to="{ append:true,path: 'details', query: { id: scope.row.id,...userInfo}}" style="color:#1890ff;"><span size="small" type="text" @click="changeCession">{{ scope.row.rmaId }}</span></router-link>
          </p>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="orderNumber"
        :label="$t('title.ordernumber')"
      /> -->
      <!-- <router-link target="_blank" :to="{ path: '/order-manage/Self-delivery-order/added', query: { platform: scope.row.platFormName,merchant: scope.row.shopCode,orderNumber: scope.row.orderCode,site: scope.row.siteCode,disabled: true }}" style="color:#1890ff;">{{ scope.row.orderCode }}</router-link> -->
      <el-table-column
        :label="$t('topic.PlatformOrderNumber')"
        prop="orderCode"
        width="125"
      />
      <el-table-column
        :label="$t('title.Moreinformation')"
        prop="site"
        width="125"
      >
        <template slot-scope="scope">
          {{ $t('title.platform') }}:{{ scope.row.platFormName }}
          <br>
          {{ $t('title.shopName') }}:{{ scope.row.shopCode }}
          <br>
          {{ $t('topic.Refundmethod') }}:<span v-if="scope.row.returnType == 0"> {{ $t("topic.System") }}</span>
          <span v-if="scope.row.returnType == 1">  {{ $t("topic.Manual") }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('topic.RMAType')"
        align="center"
        prop="returnDetailTypeName"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.returnDetailType == 0"> {{ $t("topic.Refunds") }}</p>
          <p v-if="scope.row.returnDetailType == 1">  {{ $t("topic.OnlyRefund") }}</p>
          <p v-if="scope.row.returnDetailType == 3">  {{ $t("title.Returnexchange") }}</p>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('page.status')"
        align="center"
        prop="returnStatusName"
      >
        <template slot-scope="scope">
          {{ getReturnStatus(scope.row.returnStatus) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('title.time')"
        width="220"
      >
        <template slot-scope="scope">
          {{ $t('topic.generate') }}:{{ scope.row.returnCreateTime }}
          <br>
          {{ $t('topic.refunds') }}:{{ scope.row. returnPaymentTime }}
          <br>
          {{ $t('topic.review') }}:{{ scope.row.returnReviewTime }}
          <br>
          {{ $t('topic.Submissiontime') }}:{{ scope.row.signTime }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('title.note')"
        align="center"
        prop="remark"
      />
      <el-table-column
        :label="$t('title.operator')"
        width="100"
      >
        <template slot-scope="scope">
          {{ $t('title.create') }}:{{ scope.row.returnCreateName }}
          <br>
          {{ $t('topic.review') }}:{{ scope.row.returnReviewName }}
          <br>
          {{ $t('topic.refunds') }}:{{ scope.row.returnPaymentName }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('page.operate')"
        align="center"
        fixed="right"
        width="150"
      >
        <template slot-scope="scope">
          <!-- 发布 -->
          <span v-if="form.returnType !=0 && scope.row.returnStatus == 8 ">
            <el-button size="small" type="text" @click="handleRelease(scope.row)">{{ $t('title.Release') }}</el-button>
          </span>
          <!--审核-->
          <span v-if="form.returnType != 0 && scope.row.returnStatus == 0 ">
            <router-link :to="{ append:true,path: 'review', query: { id: scope.row.id,...userInfo}}"><el-button size="small" type="text">{{ $t('topic.review') }}</el-button></router-link>
          </span>
          <!--修改-->
          <span v-if="form.returnType != 0 && scope.row.returnStatus == 8">
            <router-link :to="{ append:true,path: 'added', query: { id: scope.row.id,...userInfo}}"><el-button size="small" type="text">{{ $t('page.modify') }}</el-button></router-link>
            <!-- <el-button type="text" size="small" @click="handModifiy(scope.row)">修改</el-button> -->
          </span>
          <!-- 确定收货 -->
          <span v-if="form.returnType != 0 && scope.row.returnStatus == 2 ">
            <el-button size="small" type="text" @click="handleReceiptOrder(scope.row)">{{ $t('topic.Confirmreceipt') }}</el-button>
          </span>
          <!-- 退款-->
          <span v-if="form.returnType != 0 && (scope.row.returnStatus == 3 || scope.row.returnStatus == 20)">
            <router-link :to="{ append:true,path: 'walmart-refunds', query: { id: scope.row.id,...userInfo}}"><el-button size="small" type="text" @click="changeCession">{{ $t('topic.refunds') }}</el-button></router-link>
            <!-- <el-button type="text" size="small" @click="handleRefund(scope.row)">退款</el-button> -->
          </span>
          <!-- 重推 -->
          <span v-if="form.returnType !=0 && scope.row.returnStatus == 6">
            <router-link :to="{ append:true,path: 'walmart-refunds', query: { id: scope.row.id,repostType:1,...userInfo}}"><el-button size="small" type="text">{{ $t('title.Pushagain') }}</el-button></router-link>
          </span>
          <!-- 失败原因 -->
          <span v-if="form.returnType !=0 && scope.row.returnStatus == 6">
            <el-button size="small" type="text" @click="handleReson(scope.row)">{{ $t('topic.Reasonforfailure') }}</el-button>
          </span>
          <!--取消-->
          <span v-if="form.returnType !=0 && (scope.row.returnStatus == 20 || scope.row.returnStatus == 0 || scope.row.returnStatus == 2 ||scope.row.returnStatus == 1 || scope.row.returnStatus == 3 || scope.row.returnStatus == 8 || scope.row.returnStatus == 6 )">
            <el-button size="small" type="text" @click="handcancleOrder(scope.row)">{{ $t('title.cancel') }}</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" @update="pagerUpdate" />
    <!-- 物流跟踪号 -->
    <el-dialog
      :title="$t('topic.Agreetoreturn')"
      :visible.sync="buyLableDialogVisible"
      width="60%"
    >
      <el-row :span="24">
        <p />
        <el-form ref="ebayForm" :model="ebayForm" label-width="130px">
          <el-col :span="15">
            <el-form-item :label="$t('topic.logisticsprovider')">
              <el-input v-model="ebayForm.returnCarrier" />
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item :label="$t('topic.trackingnumber')">
              <el-input v-model="ebayForm.trackingNumber" oninput="value=value.replace(/[^\a-\z\A-\Z0-9]/g, '')" />
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialogVisible">{{ $t('title.cancel') }}</el-button>
        <el-button :loading="saveLoding" type="primary" @click="confirmDefund">{{ $t('page.sure') }}</el-button>
      </span>
    </el-dialog>
    <update-trace :row="currentRow" :visible.sync="updateTraceVisible" @refresh="_getReturnsInfos(queryParameter)" />
    <bulk-refund-dialog
      :platform="platform"
      :platform-id="form.sellPlatId"
      :refund-list="multipleSelection"
      :visible.sync="bulkRefundDialogVisible"
      @refresh="_getReturnsInfos(queryParameter)"
    />
  </div>
</template>
<script>
import Paging from '@/components/Paging'
import {
  checkReturnInfos,
  getAllStatusCounts,
  getErrorMsgById,
  getReturnsInfos,
  listSysBaseConfig,
  querySiteList,
  queryTerraceList,
  saveReturnInfos,
  updateStatus
} from '@/api/omsorder'
import oms_base, { unifiedExport } from '@/api/a-base-host'
import Cookies from 'js-cookie'
import mixin from '@/mixin/oms-mixin'
import UpdateTrace from '../components/UpdateTrace'
import BulkRefundDialog from '../components/BulkRefundDialog.vue'
import { exportSuccess } from '@/utils'

export default {
  components: {
    Paging,
    UpdateTrace,
    BulkRefundDialog
  },
  mixins: [mixin],
  data() {
    return {
      exportLoading: false,
      bulkRefundDialogVisible: false,
      updateTraceVisible: false,
      currentRow: null,
      language: '',
      warehouseOptions: [],
      toalePrice: '', // 总运费
      refundForm: {},
      ShipForm: {},
      detailList: [],
      createDialog: false,
      platform: '',
      sites: [],
      showRow: false, // 切换显示input
      tableDatas: [],
      terraceData: [],
      siteData: [],
      // detailList: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      ebayForm: {
        trackingNumber: '',
        returnCarrier: ''
      },
      buyLableDialogVisible: false,
      saveLoding: false,
      form: {
        operatorType: '0',
        operatorName: '0',
        trackingId: '',
        rmaId: '',
        createByName: '',
        date: [],
        platForm: '',
        siteCode: '',
        orderCode: '',
        sku: '',
        returnDetailType: '',
        returnType: '-1',
        returnStatus: 3
      },
      refundType: '',
      topreator: false,
      flag: true,
      order: {},
      returnReasonOptions: [],
      multipleSelection: [],
      tableDatasLoading: false,
      tableData: [],
      statusData: [],
      expands: [],
      sidvoList: [],

      getRowKeys: (row) => {
        return row.id
      }
    }
  },
  computed: {
    isTimeout() {
      return this.form.returnStatus === 20
    },
    isReceived() {
      return this.form.returnStatus === 3
    },
    exportUrl() {
      const [operatorStartTime, operatorEndTime] = this.form.date || []
      const { operatorType, operatorName, trackingId, rmaId, platForm, siteCode, orderCode, sku, returnDetailType, returnType, returnStatus } = this.form
      return oms_base + `/oms/returns/exportReturnInfos?platForm=${platForm}&siteCode=${siteCode}&orderCode=${orderCode}&sku=${sku}&returnDetailType=${returnDetailType}&returnType=${returnType}&returnStatus=${returnStatus}&operatorType=${operatorType}&operatorName=${operatorName}&trackingId=${trackingId}&rmaId=${rmaId}&operatorStartTime=${operatorStartTime || ''}&operatorEndTime=${operatorEndTime || ''}`
    },
    // 生成对应的请求参数
    queryParameter() {
      const [operatorStartTime, operatorEndTime] = this.form.date || []
      return Object.assign({}, this.pager, this.form, { operatorStartTime, operatorEndTime })
    },
    acontParameter() {
      const [operatorStartTime, operatorEndTime] = this.form.date || []
      return Object.assign(this.form, { operatorStartTime, operatorEndTime })
    }
  },
  mounted() {
    const data = sessionStorage.getItem('queryForm')
    this.form = data ? JSON.parse(data) : this.form
    this.language = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    this._queryTerraceList()
    this._listSysBaseConfig()
  },
  methods: {
    pagerUpdate(val) {
      this.pager = val
      this._getReturnsInfos(this.queryParameter)
    },
    // 退货仓
    async _listSysBaseConfig(data) {
      const { datas } = await listSysBaseConfig({ bizType: 'Return_Warehouse' })
      this.warehouseOptions = datas
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 平台条件查询
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      // this.terraceData = datas
      datas.map(e => {
        if (e.platformShortName === 'Walmart') {
          this.form.sellPlatId = e.id
          this.sellPlatName = e.platformShortName
          this.form.platForm = e.id
        }
        if (e.platformShortName === 'Walmart') {
          this.terraceData.push(e)
        }
      })
      this._querySiteList({ sellPlatformId: this.form.sellPlatId })
    },
    // 站点条件查询
    async _querySiteList(params) {
      const { datas } = await querySiteList(params)
      this.siteData = []
      datas.map(e => {
        if (sessionStorage.getItem('queryForm') === null) {
          if (e.siteCode === 'US') {
            this.form.siteId = e.id
            this.form.siteCode = e.siteCode
            this.siteData.push(e)
          }
        } else {
          if (e.siteCode === 'US') {
            this.siteData.push(e)
          }
        }
      })
      // 获取到平台和站点的值后,再获取SKU信息数据
      this._getReturnsInfos(this.queryParameter)
    },
    // 选择平台和站点
    selectTerrace(type, data) {
      if (type === 'terrace') { // 平台
        this.form.sellPlatId = data.id
        this.sellPlatName = data.platformShortName
        this.form.platForm = data.id

        // 获取当前选中平台下第一个站点
        for (let i = 0; i < this.siteData.length; i++) {
          if (this.siteData[i].sellPlatformId === this.form.sellPlatId) {
            this.form.siteId = this.siteData[i].id
            this.form.siteCode = this.siteData[i].siteCode
            break
          }
        }
      } else {
        this.form.siteId = data.id
        this.form.siteCode = data.id
      }
      const { returnStatus, platForm, siteCode, descs } = this.form
      const { size, current, total } = this.pager
      this._getReturnsInfos({ returnStatus, platForm, siteCode, descs, size, current, total })
    },
    // 点击查询条件获取信息
    queryClick(query) {
      this.pager.current = 1
      if (this.form.createByName === undefined && this.form.rmaId === undefined && this.form.orderCode === '' && this.form.sku === '' && this.form.trackingId === undefined) {
        this._getReturnsInfos(this.queryParameter)
      } else {
        this.queryParameter.returnStatus = ''
        this.acontParameter.returnStatus = ''
        this._getReturnsInfos(this.queryParameter)
      }
    },
    // 点击重置清空文本框信息
    resetClick(val) {
      this.form.rmaId = ''
      this.form.orderCode = ''
      this.form.sku = ''
      this.form.trackingId = ''
      this.form.date = []
      this.form.shipperAddress = ''
      this.form.createByName = ''
      this.queryParameter.returnStatus = 3
      this.acontParameter.returnStatus = 3
      this._getReturnsInfos(this.queryParameter)
    },
    async _getAllStatusCounts(parame) {
      const { datas } = await getAllStatusCounts(parame)
      this.statusData = datas
    },
    getReturnStatus(status) {
      switch (status + '') {
        case '2':
          return this.$t('title.Unreceived')
        case '3':
          return this.$t('title.Received')
        case '4':
          return this.$t('title.Refunded')
        case '5':
          return this.$t('title.Refused')
        case '6':
          return this.$t('title.Failedrefund')
        case '7':
          return this.$t('title.Canceled')
        case '8':
          return this.$t('title.draft')
        case '9':
          return this.$t('title.Partiallyreceived')
        case '10':
          return this.$t('title.Refunding')
        case '11':
          return this.$t('title.Transferring')
        case '20':
          return this.$t('title.Deliveredreceived')
        default:
          return ''
      }
    },
    async _getReturnsInfos(parames) {
      this.currentRow = null
      this.pager.current = 1
      try {
        this.tableDatasLoading = true
        const { datas: { pager, records }} = await getReturnsInfos(parames)
        this.tableData = records
        this.pager = pager
        this._getAllStatusCounts(this.acontParameter)
        sessionStorage.removeItem('queryForm')
      } finally {
        this.tableDatasLoading = false
      }
    },
    selectStatus(item) {
      this.pager.current = 1
      if (item === '') { // 全部
        this.queryParameter.returnStatus = ''
        this.form.returnStatus = ''
      } else { // 其他
        this.queryParameter.returnStatus = item.returnStatus
        this.form.returnStatus = item.returnStatus
      }
      this._getReturnsInfos(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getReturnsInfos(this.queryParameter)
    },
    // 批量选中
    handleSelectionChange(val) {
      val.map(e => { e.operatorId = 99 })
      this.multipleSelection = val
    },
    // 批量取消
    handleCancleRMA() {
      this.$confirm(this.$t('tips.Reinstated'), this.$t('tips.Cancellation'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(() => {
        this._updateStatus(this.multipleSelection)
      })
    },
    // 批量退款
    handleBulkRefund() {
      if (!this.multipleSelection.length) {
        this.$message.warning(this.$t('tips.Selectleast'))
        return
      }
      this.platform = 'walmart'
      this.bulkRefundDialogVisible = true
    },
    // 取消退货单
    handcancleOrder(row) {
      const list = []
      row.operatorId = 99
      list.push(row)
      this.$confirm(this.$t('tips.Reinstated'), this.$t('tips.Cancellation'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(() => {
        this._updateStatus(list)
      })
    },
    // 确定已收货
    handleReceiptOrder(row) {
      const list = []
      row.operatorId = 2
      list.push(row)
      this.$confirm(this.$t('tips.ConfirmReceive'), this.$t('tips.ReceivingAlert'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(() => {
        this._updateStatus(list)
      })
    },
    // 更新物流单号
    handleUpdateTrackingId(row) {
      this.updateTraceVisible = true
      this.currentRow = row
    },
    // 补录物流单号
    handleMakeup(row, item) {
      this.buyLableDialogVisible = true
      this.makeUpLiast = item
      this.makeUpLiast.detailList = []
      this.makeUpLiast.detailList.push(row)
    },
    confirmDefund() {
      this.makeUpLiast.detailList.map(e => {
        e.trackingId = this.ebayForm.trackingNumber
        e.returnCarrier = this.ebayForm.returnCarrier
      })
      this.makeUpLiast.operatorId = 1
      const list = []
      list.push(this.makeUpLiast)
      this._updateStatus(list)
    },
    closeDialogVisible() {
      this.buyLableDialogVisible = false
      this.ebayForm.trackingNumber = ''
      this.ebayForm.returnCarrier = ''
    },
    // 点击修改
    handModifiy(row) {
      this.createDialog = true
      this.order = row
    },
    // 发布
    handleRelease(row) {
      this.detailList = row
      this._checkReturnInfos(row)
    },
    // 校验手工创建RAM
    async _checkReturnInfos(data) {
      const { code, msg } = await checkReturnInfos(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.detailList.detailList.map(e => {
          this.refundType = e.refundType
          e.refundAmount = (parseFloat(e.itemPrice) - Math.abs(parseFloat(e.promotionDiscount)) + parseFloat(e.otherRefund) - parseFloat(e.shippingPrice)).toFixed(2)
        })
        if (this.detailList.detailList[0].refundType === 1 || this.detailList.detailList[0].refundType === 2) {
          this.$router.push({
            path: './walmart-refunds',
            append: true,
            query: { 'id': this.detailList.id, 'repostType': 2, ...this.userInfo }
          })
          return false
        } else {
          const data = {}
          const { rmaId, id, remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername, shipperAddress } = this.detailList
          data.detailList = this.detailList.detailList
          data.imageList = this.detailList.imageList
          data.returnsInfo = { rmaId, id, remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
          data.returnsTrack = { shipperAddress, orderCode }
          data.operating = 1
          await this._saveReturnInfos(data)
        }
      }
    },
    // 保存或者发布后的返回信息
    async _saveReturnInfos(data) {
      const { code, msg } = await saveReturnInfos(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        await this._getReturnsInfos(this.queryParameter)
      }
    },
    // 点击失败原因按钮 获取退款失败原因
    handleReson(row) {
      this._getErrorMsgById(row.id)
    },
    async _getErrorMsgById(id) {
      const { datas } = await getErrorMsgById(id)
      const msg = datas
      const h = this.$createElement
      var text = msg
      var regexp = /((http|ftp|https|file):\/\/([\w\-]+\.)+[\w\-]+(\/[\w\-\.\/?\@\%\!\&=\+\~\:\#\;\,]*)?)/ig
      text = text.errorMsg.replace(regexp, ' ')
      var errorReason = text.replace(' For help on how to proceed, see', ' ')
      this.$msgbox({
        title: this.$t('tips.FalilureAlert'),
        message: h('p', null, [
          h('span', null, this.$t('tips.Falilure') + errorReason),
          h('br', null),
          h('br', null),
          h('span', null, this.$t('tips.FalilureXML') + msg.originXml)
        ]),
        customClass: 'megBox',
        confirmButtonText: this.$t('page.sure')
      })
    },
    // 更新状态
    async _updateStatus(data) {
      const { code, msg } = await updateStatus(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.buyLableDialogVisible = false
        this.ebayForm.trackingNumber = ''
        this.ebayForm.returnCarrier = ''
        await this._getReturnsInfos(this.queryParameter)
      }
    },
    changeInput(index) {
      var data = this.detailList.detailList[index]
      this.$set(this.detailList.detailList, index, data)
    },
    changeNumber(index, e) {
      var data = this.detailList.detailList[index]
      if (e !== undefined) {
        data.shippingPriceCheckbox = e
      }

      // 订单金额不能大于原本金额
      if (parseFloat(data.itemPrice) > data.myItemPrice) {
        this.$confirm(this.$t('tips.EnterOriginal'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemPrice = data.myItemPrice
        return
      }
      if (parseFloat(data.otherRefund) > 20) {
        this.$confirm(this.$t('tips.LesserThan20'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.otherRefund = 0
        return
      }
      // 计算总金额
      if (data.shippingPriceCheckbox === true) {
        data.refundAmount = (parseFloat(data.itemPrice) - Math.abs(parseFloat(data.promotionDiscount)) + parseFloat(data.otherRefund) - parseFloat(data.shippingPrice)).toFixed(2)
        this.$set(this.detailList.detailList, index, data)
      } else {
        data.refundAmount = (parseFloat(data.itemPrice) - Math.abs(parseFloat(data.promotionDiscount)) + parseFloat(data.otherRefund)).toFixed(2)
        this.$set(this.detailList.detailList, index, data)
      }
      this.$set(this.detailList.detailList, index, data)
    },
    // 导出文件
    async handleExport() {
      const [operatorStartTime = '', operatorEndTime = ''] = this.form.date || []
      const { operatorType, operatorName, trackingId, rmaId, platForm, siteCode, orderCode, sku, returnDetailType, returnType, returnStatus } = this.form
      const obj = {
        platForm,
        siteCode,
        orderCode,
        sku,
        returnDetailType,
        returnType,
        returnStatus,
        operatorType,
        operatorName,
        trackingId,
        rmaId,
        operatorStartTime,
        operatorEndTime
      }
      await unifiedExport({
        appName: 'oms',
        exportType: 'rma-order',
        reqParam: JSON.stringify(obj)
      }).finally(() => {
        this.exportLoading = false
      })
      exportSuccess()
    },
    handlerefresh() {
      this._getReturnsInfos({ platForm: this.form.platForm, siteCode: this.form.siteCode, descs: this.form.descs, size: this.pager.size, current: this.pager.current, total: this.pager.total })
    },
    changeReturnType(val) {
      this._getReturnsInfos(this.queryParameter)
    },
    changeType(val) {
      this._getReturnsInfos(this.queryParameter)
    },
    changeCession() {
      sessionStorage.setItem('queryForm', JSON.stringify(this.form))
    }
  }

}
</script>
<style lang="scss"  scope>
.el-table__expanded-cell[class*=cell] {
  padding: 20px 35px 0 10px;
}
.expand-row {
  height: 44px;
  line-height: 44px;
  text-align: center;
  &.border {
    border-bottom: 1px solid #dfe6ec;
  }
}
  .el-date-editor .el-range-separator {
    width: 24%;
    }
.active {
  background-color: #1890ff;
  color:white;
}
  .el-date-editor .el-range-separator {
    width: 24%;
    }
.el-dialog__body {
    padding: 0px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}
#refund /deep/ .el-table__expand-column{
  pointer-events: none;
}
#refund /deep/ .el-table__expand-column .el-icon{
  visibility:hidden;
}
.row-bg{
  display: inline-block;
}
.megBox{
 width: 60%;
}
</style>
